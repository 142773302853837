export default [
	/*
	{
		img: require('../imgs/projects/primocolpo.webp'),
		name: 'Primocolpo Muay Thai',
		category: 'Palestra',
		url: 'https://primocolpomuaythai.it'
	},
 	*/
	{
		img: require('../imgs/projects/panizza.webp'),
		name: 'Alberto Ghizzi Panizza',
		category: 'Fotografo',
		url: 'https://albertoghizzipanizza.com'
	},
	{
		img: require('../imgs/projects/chiarafabian.webp'),
		name: 'Dott.ssa Chiara Fabian',
		category: 'Psicologa',
		url: 'https://psicologa-infantile.it'
	},
	{
		img: require('../imgs/projects/libellus.webp'),
		name: 'Libellus',
		category: 'Blog',
		url: 'https://libellus-life.it'
	},
	{
		img: require('../imgs/projects/probassano.webp'),
		name: 'Pro Loco Bassano',
		category: 'Associazione',
		url: 'https://probassano.it'
	},
	{
		img: require('../imgs/projects/bassanofotografia.webp'),
		name: 'Bassano Fotografia',
		category: 'Catalogo fotografico',
		url: 'https://bassanofotografia.art'
	},
	{
		img: require('../imgs/projects/ais-bassano.webp'),
		name: 'Associazione Italiana Soccorritori Bassano del Grappa',
		category: 'Associazione',
		url: 'https://aisbassano.org'
	},
	{
		img: require('../imgs/projects/hidden-visions.webp'),
		name: 'Hidden Visions',
		category: 'Artista',
		url: 'https://hiddenvisions.art'
	},
	{
		img: require('../imgs/projects/combatcamp.webp'),
		name: 'Combat Camp',
		category: 'Palestra',
		url: 'https://combatcampcesenatico.it'
	},
	{
		img: require('../imgs/projects/muaythaiclan.webp'),
		name: 'Muay Thai Clan',
		category: 'Palestra',
		url: 'https://muaythaiclan.com'
	},
	{
		img: require('../imgs/projects/aregon.webp'),
		name: 'Aregon',
		category: 'Erboristeria',
		url: 'https://aregon.it'
	}
]
